import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import { Title } from './Text'

const DURATIONS = [
  { label: msg`1 mois`, value: 1 },
  { label: msg`3 mois`, value: 3 },
  { label: msg`6 mois`, value: 6 },
  { label: msg`1 an`, value: 12 },
]

interface DurationSelectorProps {
  duration: number
  setDuration: (value: number) => void
}

export const DurationSelector: React.FC<DurationSelectorProps> = ({
  duration,
  setDuration,
}) => {
  const { _: lingui } = useLingui()

  return (
    <div className="mb-6">
      <Title>
        <Trans>Durée</Trans>
      </Title>
      <div className="flex space-x-4">
        {DURATIONS.map((d) => (
          <label key={d.value} className="flex items-center space-x-2">
            <input
              type="radio"
              name="duration"
              value={d.value}
              checked={duration === d.value}
              onChange={() => setDuration(d.value)}
              className="form-radio h-5 w-5 accent-blue-600"
            />
            <span className="text-sm">{lingui(d.label)}</span>
          </label>
        ))}
      </div>
    </div>
  )
}
