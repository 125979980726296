import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import { Zone as ZoneType } from 'shared/types/fleet'
import { Room } from 'shared/types/utils'
import { NO_ROOM_VALUE } from 'shared/utils/device'
import { translatedRoomsInRange } from 'shared/utils/room.i18n'
import { collator } from 'shared/utils/web/collator'
import { HolidayZone } from './HolidayZone'
import { ZoneRegion } from './ZoneRegion'

interface Props {
  zone: ZoneType
  holidayZone: ZoneType | undefined
  rooms: Room[]
}

export const Zone: React.FC<Props> = ({ zone, rooms, holidayZone }) => {
  const { _: lingui } = useLingui()

  const sortedRooms: Room[] = rooms
    .filter((room) => room !== NO_ROOM_VALUE)
    .sort((roomA, roomB) => collator.compare(roomA, roomB))

  const regions = Object.entries(zone.regions ?? {})

  return (
    <div className="flex flex-col border-t border-gray-400 py-4 first:border-none">
      <div className="flex justify-center sm:justify-start">
        <div className="flex gap-2 font-bold">
          {zone.name}
          {holidayZone && (
            <HolidayZone
              zone={zone.name}
              holidayZone={holidayZone}
              rooms={rooms}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-1">
        {regions.length === 0 ? (
          <div className="flex flex-row justify-center sm:justify-end">
            <Trans>Toutes les chambres</Trans>
          </div>
        ) : (
          regions.map(([regionId, region]) => {
            return (
              <div
                key={regionId}
                className="mt-2 flex flex-col justify-between sm:flex-row"
              >
                <div className="m-auto flex flex-col justify-center gap-1 sm:m-0 sm:flex-row sm:gap-2">
                  <ZoneRegion region={region} />
                </div>
                <div className="flex flex-row justify-center sm:justify-end">
                  {lingui(
                    translatedRoomsInRange(sortedRooms, region.roomRange),
                  )}
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}
