import React from 'react'

export type TextProps = React.HTMLAttributes<HTMLDivElement>

export const Text = React.forwardRef<HTMLDivElement, TextProps>(
  ({ className, ...props }, ref) => {
    return <div className={className ?? ''} ref={ref} {...props} />
  },
)
Text.displayName = 'Text'

export const Title: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <Text
    className={`pb-4 pt-8 text-center text-2xl md:text-left ${className ?? ''}`}
  >
    {children}
  </Text>
)
