import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { I18nProvider } from '@lingui/react'
import { ErrorBoundary } from '@sentry/react'
import React, { useEffect } from 'react'
import { Login } from './Login'
import { ReportLoader } from './ReportLoader'
import { UserManager } from './UserManager'
import { Button } from './components/Button'
import { useAuth } from './hooks/useAuth'
import { loadBrowserLanguage } from './i18n'

const showUserManager = window.location.href.indexOf('?users') >= 0

export function App() {
  useEffect(() => {
    const asyncLoadBrowserLanguage = async () => await loadBrowserLanguage()
    asyncLoadBrowserLanguage()
  }, [])

  return (
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ErrorBoundary
          fallback={
            <div className="flex min-h-screen flex-col items-center justify-center">
              <div className="py-4">
                <Trans>Une erreur est survenue</Trans>
              </div>
              <Button
                onClick={() => {
                  window.location.reload()
                }}
              >
                <Trans>Rafraîchir</Trans>
              </Button>
            </div>
          }
        >
          <Authentification />
          {import.meta.env.MODE !== 'production' && (
            <div className="pointer-events-none fixed bottom-2 right-2 text-lime-300">
              <b>STAGING</b>
            </div>
          )}
        </ErrorBoundary>
      </I18nProvider>
    </React.StrictMode>
  )
}

const Authentification: React.FC = () => {
  const { loading, error, data: user } = useAuth()

  return (
    <div className="min-h-screen">
      {loading ? (
        <div className="flex min-h-screen flex-col justify-center text-center">
          <Trans>Authentification...</Trans>
        </div>
      ) : error !== null ? (
        <div className="flex min-h-screen flex-col justify-center text-center">
          <Trans>Une erreur est survenue</Trans>
        </div>
      ) : user === null ? (
        <Login />
      ) : (
        <div className="min-h-screen">
          {showUserManager ? <UserManager /> : <ReportLoader />}
        </div>
      )}
    </div>
  )
}
