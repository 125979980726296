import { Trans } from '@lingui/macro'
import { DateActivity, HourActivity } from 'common/types'
import React, { useMemo, useState } from 'react'
import { TimeRange } from 'shared/types/timeRange'
import { DateString, Room } from 'shared/types/utils'
import { roomDisplayName } from 'shared/utils/room'
import { ActivityLegend } from './ActivityLegend'
import { RoomActivityGraph } from './RoomActivityGraph'
import { Title } from './components/Text'
import { ActivityType } from './utils/activity'

interface Props {
  dates: DateString[]
  monitoringTimeRange: TimeRange
  activity: DateActivity
  selectedRoom: Room
}

export const ActivityGraphs: React.FC<Props> = ({
  dates,
  monitoringTimeRange,
  activity,
  selectedRoom,
}) => {
  const roomActivities = useMemo(() => {
    // Transpose date/room data structure from database into room/date
    const roomActivities: Record<Room, Record<DateString, HourActivity>> = {}
    dates.forEach((date) => {
      Object.entries(activity[date] ?? {}).forEach(([room, hourActivity]) => {
        roomActivities[room] ??= {}
        roomActivities[room][date] = hourActivity ?? {}
      })
    })
    return roomActivities
  }, [dates, activity])

  const [selectedActivity, setSelectedActivity] = useState<ActivityType>()

  const handleActivityClick = (activity: ActivityType) => {
    if (selectedActivity === activity) {
      setSelectedActivity(undefined)
    } else {
      setSelectedActivity(activity)
    }
  }

  return (
    <>
      <Title>
        <Trans>Activité {roomDisplayName(selectedRoom)}</Trans>
      </Title>
      <ActivityLegend
        selectedActivity={selectedActivity}
        setSelectedActivity={handleActivityClick}
      />
      <RoomActivityGraph
        dates={dates}
        dateActivity={roomActivities[selectedRoom] ?? {}}
        monitoringTimeRange={monitoringTimeRange}
        selectedActivity={selectedActivity}
      />
    </>
  )
}
