import { t } from '@lingui/macro'
import { DateData } from 'common/types'
import React, { useMemo } from 'react'
import { DateAlerts } from 'shared/types/reporting'
import { DateString } from 'shared/types/utils'
import { DateSelectGraph } from './DateSelectGraph'

export const AlertsGraph: React.FC<{
  dates: DateString[]
  alerts: DateAlerts
  selectedDate: string
  setSelectedDate: (_: string) => void
}> = ({ dates, alerts, selectedDate, setSelectedDate }) => {
  const alertsByDate = useMemo(
    () =>
      Object.entries(alerts).reduce<DateData<number>>(
        (acc, [date, dateData]) => {
          const nbAlerts = Object.values(dateData ?? {}).reduce(
            (acc, roomData) => {
              return acc + Object.keys(roomData).length
            },
            0,
          )
          acc[date] = nbAlerts
          return acc
        },
        {},
      ),
    [alerts],
  )

  return (
    <DateSelectGraph
      dates={dates}
      dateValues={alertsByDate}
      dataName={t`alerte`}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
    />
  )
}
