import { Plural, Trans } from '@lingui/macro'
import React, { useMemo, useState } from 'react'
import { enableAudioPlayer } from 'shared/types/alert'
import { Alert, DateAlerts } from 'shared/types/reporting'
import { DateString, Room } from 'shared/types/utils'
import { roomDisplayName } from 'shared/utils/room'
import { formatIsoTime } from 'shared/utils/time'
import { Player } from './Player'
import { displayType } from './alertType'
import { Title } from './components/Text'
import { formatDate } from './utils/date'

interface Props {
  alerts: DateAlerts
  rooms: Room[]
}

type AlertWithDate = Alert & { day: DateString }

export const AlertsByRoom: React.FC<Props> = ({ alerts, rooms }) => {
  const alertsByRoom = useMemo(
    () =>
      rooms.reduce<Record<Room, AlertWithDate[]>>((acc, room) => {
        const roomAlerts: AlertWithDate[] = Object.entries(alerts).flatMap(
          ([date, roomAlerts]) =>
            Object.values((roomAlerts ?? {})[room] ?? {}).map((alert) => ({
              ...alert,
              day: date,
            })),
        )
        acc[room] = roomAlerts
        return acc
      }, {}),
    [alerts, rooms],
  )

  const [selectedRoom, setSelectedRoom] = useState(rooms[0])

  return (
    <div>
      <div className="sticky top-0 z-10 -ml-4 bg-white pl-4">
        <Title>
          <Trans>Alertes par chambre</Trans>
        </Title>
      </div>
      <div className="mb-6 text-sm">
        <Trans>Cliquez sur une chambre pour la sélectionner</Trans>
      </div>
      <div className="flex flex-row justify-start gap-6">
        <div>
          {rooms.map((room) => (
            <div
              key={room}
              onClick={() => setSelectedRoom(room)}
              className="relative pl-1 even:bg-gray-100"
            >
              <div className="group flex flex-row items-stretch">
                <div
                  className={`-ml-4 flex flex-row items-center py-1 pl-4 group-hover:bg-blue-200 ${
                    room === selectedRoom ? 'bg-blue-300 shadow' : ''
                  }`}
                >
                  <div className="w-48">{roomDisplayName(room)}</div>
                  <div className="w-32">
                    <Plural
                      value={alertsByRoom[room].length}
                      one="# alerte"
                      other="# alertes"
                    />
                  </div>
                </div>
              </div>

              {room === selectedRoom && (
                <div className="border-l-4 border-blue-300 bg-white py-2 pl-2 md:absolute md:left-full md:top-0 md:py-0">
                  <table className="table-auto">
                    <tbody>
                      {alertsByRoom[selectedRoom].map((alert) => (
                        <tr key={alert.day + alert.date}>
                          <td className="pr-2">{formatDate(alert.day)}</td>
                          <td className="pr-2">{formatIsoTime(alert.date)}</td>
                          <td className="py-1 pr-2">
                            {displayType(alert.type)}
                          </td>
                          <td>
                            {enableAudioPlayer(alert.type) && (
                              <Player soundURL={alert.soundURL} />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </div>

        {!selectedRoom && (
          <div>
            <Trans>Aucune alerte</Trans>
          </div>
        )}
      </div>
    </div>
  )
}
