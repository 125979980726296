import { UserId } from 'shared/types/utils'
import { get } from './firebaseMethods'

export async function isUserAdmin(uid: UserId) {
  try {
    const isAdmin = await get(`adminUsers/${uid}`)
    return isAdmin === true
  } catch (error) {
    // in case user is not admin, no read permission in adminUsers
    return false
  }
}
