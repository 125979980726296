import { Trans, t } from '@lingui/macro'
import {
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { onError } from 'shared/utils/web/error'
import { Button } from './components/Button'
import { Title } from './components/Text'
import { auth } from './firebase'

export const Login = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const [email, setEmail] = useState(searchParams.get('email') ?? '')
  const [password, setPassword] = useState(searchParams.get('password') ?? '')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [isForgottenPassword, setIsForgottenPassword] = useState(false)

  const showGoogle = window.location.href.indexOf('?google') >= 0

  function googleAuth(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault()
    reset()
    signInWithRedirect(auth, new GoogleAuthProvider()).catch((error) => {
      onError(error)
      setError(t`Accès refusé`)
    })
  }

  function reset() {
    setError('')
    setMessage('')
  }

  function toggleMode() {
    setIsForgottenPassword(!isForgottenPassword)
    reset()
  }

  async function submit(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.preventDefault()
    reset()

    try {
      if (isForgottenPassword) {
        await sendPasswordResetEmail(auth, email, {
          url: `${window.location.origin}?email=${email}`,
        })
        setMessage(t`Suivez les instructions de l'email qui vous a été envoyé`)
      } else {
        await signInWithEmailAndPassword(auth, email, password)
        setMessage(t`Authentification réussie`)
      }
    } catch (error) {
      onError(error)
      setError(t`Accès refusé`)
    }
  }

  // Run once on start
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const email = searchParams.get('email') ?? ''
    const password = searchParams.get('password') ?? ''
    if (email.length > 0 && password.length > 0)
      signInWithEmailAndPassword(auth, email, password)
  }, [])

  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-6">
      <Title>
        OSO - <Trans>Rapports</Trans>
      </Title>
      <form className="flex flex-col items-center gap-4">
        <b>
          {isForgottenPassword ? (
            <Trans>Entrez votre email</Trans>
          ) : (
            <Trans>Identifiez-vous</Trans>
          )}
        </b>
        {showGoogle ? (
          <Button onClick={googleAuth}>
            <Trans>Se connecter avec Google</Trans>
          </Button>
        ) : (
          <>
            <input
              className="outline-hidden min-w-fit rounded-md border-0 bg-blue-100 px-2 py-1"
              type="email"
              size={25}
              placeholder={t`Email`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!isForgottenPassword && (
              <input
                className="outline-hidden min-w-fit rounded-md border-0 bg-blue-100 px-2 py-1"
                type="password"
                size={25}
                placeholder={t`Mot de passe`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            )}
            <Button onClick={submit}>
              <Trans>Valider</Trans>
            </Button>
          </>
        )}
      </form>
      <div className="cursor-pointer text-indigo-900" onClick={toggleMode}>
        {isForgottenPassword ? (
          <Trans>Revenir à la connexion classique</Trans>
        ) : (
          <Trans>Mot de passe oublié ?</Trans>
        )}
      </div>
      {message && (
        <div className="rounded-md bg-lime-300 px-4 py-2">{message}</div>
      )}
      {error && <div className="rounded-md bg-red-300 px-4 py-2">{error}</div>}
      <div className="text-center">
        <Trans>
          En cas de problème pour accéder à ce site,
          <br />
          contactez nous à{' '}
          <a className="text-indigo-900" href="mailto:support@oso-ai.com">
            support@oso-ai.com
          </a>
          .
        </Trans>
      </div>
    </div>
  )
}
