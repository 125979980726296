import { Trans } from '@lingui/macro'
import { DatabaseSchema } from 'common/databaseSchema'
import React, { useMemo, useState } from 'react'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { DateString, FacilityId } from 'shared/types/utils'
import { Connectivity } from './Connectivity'
import { ConnectivityGraph } from './ConnectivityGraph'
import { demoFacilityId } from './Report'
import { Title } from './components/Text'
import { useMergedFirebase } from './hooks/useMergedFirebase'

interface Props {
  dates: DateString[]
  facilityId: FacilityId
}

export const ConnectivityReport: React.FC<Props> = ({ dates, facilityId }) => {
  const [selectedDate, setSelectedDate] = useState(dates[dates.length - 1])

  // Load all connectivities, by date
  const refPathsMap = useMemo(
    () =>
      dates.reduce<Record<string, string>>((acc, date, index) => {
        if (facilityId === demoFacilityId)
          acc[date] = `demo/connectivity/${index}`
        else acc[date] = `connectivity/${facilityId}/${date}`
        return acc
      }, {}),
    [dates, facilityId],
  )

  const {
    data: connectivity,
    loading,
    error,
  } = useMergedFirebase<
    MergedType<'connectivity/${string}/${string}', DatabaseSchema>
  >(refPathsMap)

  return loading ? (
    <div className="my-24 text-center">
      <Trans>Chargement...</Trans>
    </div>
  ) : error ? (
    <div className="my-24 text-center">
      <Trans>Erreur</Trans>
    </div>
  ) : (
    <>
      <div className="flex items-center">
        <Title>
          <Trans>Chambres hors ligne</Trans>
        </Title>
        <span>&nbsp;*</span>
      </div>
      <ConnectivityGraph
        dates={dates}
        connectivity={connectivity}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <Connectivity
        date={selectedDate}
        connectivity={connectivity[selectedDate] ?? {}}
      />
      <div className="pt-4">
        <Trans>
          * Une chambre est considérée hors ligne dès qu'elle n'a pas pu envoyer
          de sons pendant <b>une heure dans la journée</b>.
        </Trans>
      </div>
      <div className="pt-4">
        <Trans>
          Cet historique est mis à jour quotidiennement, les téléphones
          affichent eux l'état en temps réel.
        </Trans>
      </div>
    </>
  )
}
