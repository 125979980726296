import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import { AlertType } from 'shared/types/alert'
import { ALERT_TYPE_TRANSLATE } from 'shared/types/alert.i18n'
import { Title } from './Text'

interface AlertTypesSelectorProps {
  allAlertTypes: AlertType[]
  selectedAlertTypes: AlertType[]
  toggleAlertType: (type: AlertType) => void
}

export const AlertTypesSelector: React.FC<AlertTypesSelectorProps> = ({
  allAlertTypes,
  selectedAlertTypes,
  toggleAlertType,
}) => {
  const { _: lingui } = useLingui()

  return (
    <div className="mb-6">
      <Title>
        <Trans>Type d'alertes</Trans>
      </Title>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {allAlertTypes.map((type) => (
          <label key={type} className="flex items-center space-x-2">
            <input
              type="checkbox"
              value={type}
              checked={selectedAlertTypes.includes(type)}
              onChange={() => toggleAlertType(type)}
              className="form-checkbox h-5 w-5 accent-blue-600"
            />
            <span className="text-sm">
              {lingui(ALERT_TYPE_TRANSLATE[type])}
            </span>
          </label>
        ))}
      </div>
    </div>
  )
}
