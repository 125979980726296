import { Plural, Trans } from '@lingui/macro'
import React, { useMemo, useState } from 'react'
import { enableAudioPlayer } from 'shared/types/alert'
import { Alert, DateAlerts } from 'shared/types/reporting'
import { DateString } from 'shared/types/utils'
import { roomDisplayName } from 'shared/utils/room'
import { formatIsoTime } from 'shared/utils/time'
import { AlertsGraph } from './AlertsGraph'
import { Player } from './Player'
import { displayType } from './alertType'
import { Title } from './components/Text'
import { formatDayOfWeek } from './utils/date'

interface Props {
  dates: DateString[]
  alerts: DateAlerts
}

export const AlertsByDate: React.FC<Props> = ({ dates, alerts }) => {
  const [selectedDate, setSelectedDate] = useState(dates[dates.length - 1])

  const selectedDateAlerts = useMemo(
    () =>
      Object.entries(alerts[selectedDate] ?? {})
        .reduce<(Alert & { room: string })[]>((acc, [room, roomData]) => {
          acc.push(
            ...Object.values(roomData).map((alert) => ({
              ...alert,
              room,
            })),
          )
          return acc
        }, [])
        .sort((alertA, alertB) => alertA.date.localeCompare(alertB.date)),
    [selectedDate, alerts],
  )

  return (
    <div className="mb-16 flex flex-col gap-4">
      <div className="sticky top-0 bg-white">
        <Title>
          <Trans>Alertes par date</Trans>
        </Title>
        <AlertsGraph
          dates={dates}
          alerts={alerts}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      <div className="text-lg">
        <Plural
          value={selectedDateAlerts.length}
          one={`# alerte le ${formatDayOfWeek(selectedDate)}`}
          other={`# alertes le ${formatDayOfWeek(selectedDate)}`}
        />
      </div>

      <div
        key={selectedDate}
        className="grid max-w-lg grid-cols-[auto_2fr_3fr_auto] gap-x-1 gap-y-2"
      >
        {selectedDateAlerts.map((alert) => (
          <React.Fragment key={`${alert.room}-${alert.date}`}>
            <div>{formatIsoTime(alert.date)}</div>
            <div className="max-w-fit overflow-hidden text-ellipsis whitespace-nowrap">
              {roomDisplayName(alert.room)}
            </div>
            {displayType(alert.type)}
            <div>
              {enableAudioPlayer(alert.type) && (
                <Player soundURL={alert.soundURL} />
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
