import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { Facility } from 'shared/types/reporting'
import { DateString, FacilityId, Room } from 'shared/types/utils'
import { isWithinTimeRange } from 'shared/utils/timeRange'
import { ActivityReport } from './ActivityReport'
import { AlertsReport } from './AlertsReport'
import { ConnectivityReport } from './ConnectivityReport'
import { Contacts } from './Contacts'
import { StatisticsReport } from './StatisticsReport'
import { Zones } from './Zones'

export const demoFacilityId = 'DEMO-OSO'

type Tab =
  | 'alerts'
  | 'activity'
  | 'connectivity'
  | 'contacts'
  | 'zones'
  | 'statistics'

const TABS: Record<Tab, MessageDescriptor> = {
  alerts: msg`Alertes`,
  activity: msg`Activité`,
  connectivity: msg`Connectivité`,
  contacts: msg`Répertoire`,
  zones: msg`Zones`,
  statistics: msg`Statistiques`,
}

export type ReportProps = {
  dates: DateString[]
  facilityId: FacilityId
  facility: Facility
  rooms: Room[]
}

export const Report: React.FC<ReportProps> = ({
  dates,
  facility,
  facilityId,
  rooms,
}) => {
  const [currentTab, setCurrentTab] = useState<Tab>('alerts')

  const { _: lingui } = useLingui()

  // Is noon part of monitoring time range?
  const showPeriodSelector = isWithinTimeRange(
    DateTime.fromISO('2023-01-01T12:00:00'), // only 12:00 matters
    facility.monitoringTimeRange,
  )

  return (
    <>
      <nav className="mb-6 w-full border-b text-2xl">
        <div className="flex w-0 min-w-full flex-row gap-6 overflow-x-auto md:gap-12">
          {Object.entries(TABS).map(([tab, tabName]) => (
            <a
              key={tab}
              id={`page-analytics-${tab}`}
              className={tab === currentTab ? 'border-b-2 border-blue-500' : ''}
              onClick={() => setCurrentTab(tab as Tab)}
            >
              {lingui(tabName)}
            </a>
          ))}
        </div>
      </nav>

      <Tab show={currentTab === 'alerts'}>
        <AlertsReport
          dates={dates}
          rooms={rooms}
          facilityId={facilityId}
          facility={facility}
          showPeriodSelector={showPeriodSelector}
        />
      </Tab>
      <Tab show={currentTab === 'activity'}>
        <ActivityReport
          dates={dates}
          rooms={rooms}
          facilityId={facilityId}
          monitoringTimeRange={facility.monitoringTimeRange}
        />
      </Tab>
      <Tab show={currentTab === 'connectivity'}>
        <ConnectivityReport dates={dates} facilityId={facilityId} />
      </Tab>
      <Tab show={currentTab === 'contacts'}>
        <Contacts facilityId={facilityId} />
      </Tab>
      <Tab show={currentTab === 'zones'}>
        <Zones facility={facility} rooms={rooms} />
      </Tab>
      <Tab show={currentTab === 'statistics'}>
        <StatisticsReport facilityId={facilityId} facility={facility} />
      </Tab>
    </>
  )
}

const Tab: React.FC<React.PropsWithChildren<{ show: boolean }>> = ({
  show,
  children,
}) => <div className={show ? 'block' : 'hidden'}>{children}</div>
