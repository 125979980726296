import { t } from '@lingui/macro'
import { DateConnectivity } from 'common/types'
import React, { useMemo } from 'react'
import { Nullable } from 'shared/hooks/createUseMergedFirebase'
import { DateString } from 'shared/types/utils'
import { isOffline } from './Connectivity'
import { DateSelectGraph } from './DateSelectGraph'

export const ConnectivityGraph: React.FC<{
  dates: DateString[]
  connectivity: Nullable<DateConnectivity>
  selectedDate: string
  setSelectedDate: (_: string) => void
}> = ({ dates, connectivity, selectedDate, setSelectedDate }) => {
  const connectivityByDate = useMemo(
    () =>
      Object.entries(connectivity).reduce<Record<DateString, number>>(
        (acc, [date, roomData]) => {
          // count only ari not connected
          acc[date] = Object.values(roomData ?? {}).filter(isOffline).length
          return acc
        },
        {},
      ),
    [connectivity],
  )

  return (
    <DateSelectGraph
      dates={dates}
      dateValues={connectivityByDate}
      dataName={t`chambre`}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
    />
  )
}
