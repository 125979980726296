import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import './index.css'

Sentry.init({
  enabled: !!import.meta.env.VITE_VERSION,
  dsn: 'https://c5c88a7b92e241188ea84f422ec1e102@o566331.ingest.sentry.io/6116753',
  integrations: [Sentry.browserTracingIntegration()],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
  release: `${import.meta.env.MODE}-${import.meta.env.VITE_VERSION}`,
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(<App />)
