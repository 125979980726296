import { useLingui } from '@lingui/react'
import { categoryColor } from 'shared/types/alert'
import { CATEGORY_DISPLAY_TRANSLATE } from 'shared/types/alert.i18n'
import { Region } from 'shared/types/fleet'
import { translatedTimeRangeString } from 'shared/utils/timeRange.i18n'

export const ZoneRegion = ({ region }: { region: Region }) => {
  const { _: lingui } = useLingui()

  return (
    <>
      <div
        className={`flex h-6 min-w-32 items-center justify-center rounded-full px-2 py-1 text-white 
          ${region.category ? categoryColor[region.category] : ''}`}
      >
        {region.category
          ? lingui(CATEGORY_DISPLAY_TRANSLATE[region.category])
          : ''}
      </div>
      <div className="text-center sm:text-start">
        {translatedTimeRangeString(region.timeRange)}
      </div>
    </>
  )
}
