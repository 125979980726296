import { Trans } from '@lingui/macro'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { Facilities } from 'shared/types/reporting'
import { DateString, FacilityId, Room } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { getPreviousDates } from 'shared/utils/timeRange'
import { collator } from 'shared/utils/web/collator'
import { Footer } from './Footer'
import { Header } from './Header'
import { Report, demoFacilityId } from './Report'
import { isUserAdmin } from './admin'
import { get, update } from './firebaseMethods'
import { useAuth } from './hooks/useAuth'

function reportDates() {
  const today = DateTime.now().setZone('Europe/Paris').toISODate()
  return getPreviousDates(today, 21)
}

function demoRooms() {
  const rooms = []
  for (let room = 100; room <= 123; room++) rooms.push(room.toString(10))
  for (let room = 200; room <= 217; room++) rooms.push(room.toString(10))
  return rooms
}

async function getRooms(facilityId: FacilityId, dates: DateString[]) {
  if (facilityId === demoFacilityId) return demoRooms()
  const dateRooms = await Promise.all(
    dates.map((date) => get(`rooms/${facilityId}/${date}`)),
  )
  const roomsSet = new Set<Room>()
  Object.entries(dateRooms).map(([_date, rooms]) => {
    Object.keys(rooms ?? {}).map((room) => roomsSet.add(room))
  })
  return [...roomsSet].sort(collator.compare)
}

export function ReportLoader() {
  const { data: user } = useAuth()

  // Using null to mean loading in progress
  const [facilityId, setFacilityId] = useState<FacilityId | null>(null)
  const [facilities, setFacilities] = useState<Facilities>({})
  const [rooms, setRooms] = useState<Room[] | undefined>()
  const [isAdmin, setIsAdmin] = useState(false)
  const dates = useMemo(reportDates, [])

  useEffect(() => {
    async function runASync() {
      if (user === null) return

      const dbUser = await get(`users/${user.uid}`)

      let facilities: Facilities

      // dbUser might be undefined if the user is an admin
      if (dbUser) {
        const facilityIds = Object.keys(dbUser.facilityIds ?? {})

        const facilitiesArray = await Promise.all(
          facilityIds.map((facilityId) => get(`facilities/${facilityId}`)),
        )

        facilities = facilityIds.reduce<Facilities>(
          (acc, facilityId, index) => {
            acc[facilityId] = facilitiesArray[index]
            return acc
          },
          {},
        )
      } else {
        const isAdmin = await isUserAdmin(user.uid)
        if (!isAdmin) {
          facilities = {} // Access denied
        } else {
          facilities = await get(`facilities`)
          setIsAdmin(isAdmin)
        }
      }

      const facilityId = Object.keys(facilities)[0]

      setFacilityId(facilityId)
      setFacilities(facilities)
    }

    runASync()
  }, [user])

  useEffect(() => {
    async function runASync() {
      if (!facilityId) return
      setRooms(await getRooms(facilityId, dates))
    }
    runASync()
  }, [facilityId, dates])

  useEffect(() => {
    if (!facilityId || user === null || isAdmin) return
    update(`users/${user.uid}`, {
      lastVisitFacilityId: facilityId,
      lastVisitTimestamp: serverTimestamp(),
    })
  }, [facilityId, isAdmin, user])

  const changeFacilityId = useCallback(
    (newFacilityId: FacilityId) => {
      if (newFacilityId === facilityId) return
      // Make sure previous rooms are not used when changing facility
      setFacilityId(newFacilityId)
      setRooms(undefined)
    },
    [facilityId],
  )

  return (
    <div>
      {facilityId === null ? (
        <Centered fullScreen>
          <Trans>Chargement...</Trans>
        </Centered>
      ) : isObjectEmpty(facilities) || !facilities[facilityId] ? (
        <Centered fullScreen>
          <Trans>Accès refusé</Trans>
        </Centered>
      ) : rooms === undefined ? (
        <Centered fullScreen>
          <Trans>Chargement...</Trans>
        </Centered>
      ) : (
        <div className="relative mx-auto mb-8 mt-0 min-w-min px-0 md:mt-8 md:max-w-7xl md:px-12">
          <Header
            dates={dates}
            facilityId={facilityId}
            setFacilityId={changeFacilityId}
            facilities={facilities}
            isAdmin={isAdmin}
          />
          <div className="px-4 md:px-0">
            <Report
              key={facilityId}
              dates={dates}
              facility={facilities[facilityId]}
              facilityId={facilityId}
              rooms={rooms}
            />
            <Footer />
          </div>
        </div>
      )}
    </div>
  )
}
