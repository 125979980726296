import { Trans } from '@lingui/macro'
import { signOut } from 'firebase/auth'
import React from 'react'
import { Facilities } from 'shared/types/reporting'
import { DateString, FacilityId } from 'shared/types/utils'
import { translatedTimeRangeString } from 'shared/utils/timeRange.i18n'
import { WhatsNew } from './WhatsNew'
import bg from './bg.jpg'
import { auth } from './firebase'
import logo from './oso.png'
import { formatDate } from './utils/date'

type Props = {
  dates: DateString[]
  facilityId: FacilityId
  setFacilityId: (facilityId: FacilityId) => void
  facilities: Facilities
  isAdmin: boolean
}

export const Header: React.FC<Props> = ({
  dates,
  facilityId,
  setFacilityId,
  facilities,
  isAdmin,
}) => {
  const facility = facilities[facilityId]

  return (
    <div
      className={`mb-6 flex flex-col items-stretch justify-evenly bg-cover bg-fixed bg-center bg-no-repeat py-8 text-white md:flex-row md:rounded-3xl`}
      style={{ backgroundImage: `url('${bg}')` }}
    >
      <div className="absolute right-4 top-2">
        <WhatsNew />
      </div>
      <div className="flex flex-col items-center justify-between gap-4 px-4">
        <div className="flex flex-col items-center gap-2">
          <img src={logo} alt="OSO" className="w-32" />
          <div className="text-center">
            <Trans>L'oreille augmentée des soignants</Trans>
          </div>
        </div>
        <div>
          <Trans>
            Rapport du {formatDate(dates[0])} au{' '}
            {formatDate(dates[dates.length - 1])}
          </Trans>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between gap-4 px-4">
        <div className="mt-4 flex flex-col items-center gap-2">
          <div className="flex items-center gap-3">
            <h1 className="text-center text-2xl font-black md:text-4xl">
              {facility.name}
            </h1>
            {Object.keys(facilities).length > 1 && (
              <select
                className="my-2 max-w-4 rounded bg-blue-500 hover:bg-blue-700 focus:outline-none"
                value={facilityId}
                onChange={(event) => setFacilityId(event.target.value)}
              >
                {Object.entries(facilities).map(([facilityId, facility]) => (
                  <option key={facilityId} value={facilityId}>
                    {isAdmin ? facilityId : facility.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <h3 className="text-center text-xl font-bold">
            {facility.zipCode} {facility.city}
          </h3>
        </div>
        <div>
          <Trans>
            Surveillance des chambres&nbsp;
            {translatedTimeRangeString(facility.monitoringTimeRange)}
          </Trans>
        </div>
        <div className="rounded bg-blue-500 px-2 hover:bg-blue-700">
          <a href="mailto:support@oso-ai.com">
            <Trans>Contactez notre support</Trans>
          </a>
        </div>
        {isAdmin && (
          <button
            className="rounded bg-red-500 px-2 hover:bg-red-700"
            onClick={() => (window.location.href = '/?users')}
          >
            <Trans>Gérer les utilisateurs</Trans>
          </button>
        )}
        <button
          className="rounded bg-gray-500 px-2 hover:bg-gray-700"
          onClick={() => {
            signOut(auth)
              .then(() => {
                window.location.href = '/'
              })
              .catch((error) => {
                console.error('Erreur de déconnexion :', error)
              })
          }}
        >
          <Trans>Se déconnecter</Trans>
        </button>
      </div>
    </div>
  )
}
