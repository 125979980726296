import React from 'react'

export interface Alert {
  message: string
  type: 'success' | 'error'
}

interface Props extends Alert {
  onClose: () => void
}

export const Alert: React.FC<Props> = ({ message, type, onClose }) => {
  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500'

  return (
    <div
      className={`fixed left-1/2 top-4 flex -translate-x-1/2 transform items-center gap-6 rounded-lg px-6 py-4 text-lg font-bold text-white shadow-lg ${bgColor}`}
    >
      <span>{message}</span>
      <button className="text-3xl focus:outline-none" onClick={onClose}>
        &times;
      </button>
    </div>
  )
}
