import { i18n } from '@lingui/core'
import { AlertType, alertTypeColor } from 'shared/types/alert'
import { ALERT_TYPE_TRANSLATE } from 'shared/types/alert.i18n'

export function displayType(type: AlertType) {
  return (
    <span
      className={`${alertTypeColor[type]} max-w-fit overflow-hidden text-ellipsis whitespace-nowrap rounded-full bg-opacity-30 px-2 py-0.5`}
    >
      {i18n._(ALERT_TYPE_TRANSLATE[type])}
    </span>
  )
}
