import { plural, Trans } from '@lingui/macro'
import { DateData } from 'common/types'
import React from 'react'
import { DateString } from 'shared/types/utils'
import { isDefined } from 'shared/utils/defined'
import { formatDate, getDay } from './utils/date'

interface Props {
  dates: DateString[]
  dateValues: DateData<number>
  dataName: string
  selectedDate: DateString
  setSelectedDate: (_: DateString) => void
}

export const DateSelectGraph: React.FC<Props> = ({
  dates,
  dateValues,
  dataName,
  selectedDate,
  setSelectedDate,
}) => {
  const maxValue = Math.max(1, ...Object.values(dateValues).filter(isDefined))

  return (
    <>
      <div className="text-sm">
        <Trans>Cliquez sur une date pour la sélectionner</Trans>
      </div>
      <div className="flex w-0 min-w-full flex-row items-end overflow-x-auto">
        <div className="text-sm">
          <div className="flex h-20 flex-col items-end justify-between pr-1 md:pr-2">
            <div className="pt-2">{maxValue}</div>
            <div className="-mb-2">0</div>
          </div>
          <div className={'py-2'}>&nbsp;</div>
        </div>
        {dates.map((date) => {
          const formattedDate = formatDate(date)
          const formattedDay = getDay(date)
          const value = dateValues[date] ?? 0

          return (
            <div
              className="group w-0 flex-1"
              key={date}
              onClick={() => setSelectedDate(date)}
            >
              <div
                className="relative flex h-16 flex-col justify-end bg-gray-200 pt-1"
                title={plural(value, {
                  one: `${formattedDate} : # ${dataName}`,
                  other: `${formattedDate} : # ${dataName}s`,
                })}
              >
                <div
                  className="ml-0.5 rounded-t bg-blue-500 sm:mx-0.5 lg:mx-1"
                  style={{ height: `${(100 * value) / maxValue}%` }}
                ></div>
              </div>
              <div
                className={`h-3 pt-0.5 group-hover:text-blue-200 ${
                  date === selectedDate ? 'text-blue-300' : 'text-transparent'
                }`}
              >
                <svg
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="0 0 60 10"
                  fill="currentColor"
                >
                  <polygon points="0,10 30,0 60,10" />
                </svg>
              </div>
              <div
                className={`pb-1 text-center text-sm group-hover:bg-blue-200 ${
                  date === selectedDate ? 'bg-blue-300' : ''
                }`}
              >
                <span className="hidden lg:block">{formattedDate}</span>
                <span className="mx-0.5 lg:hidden">{formattedDay}</span>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
