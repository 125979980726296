import { msg } from '@lingui/macro'
import { RoomRange } from '../types/fleet'
import { Room } from '../types/utils'
import { roomsWithinRange } from './zone'

// 🌪️ i18n warning : update accordinly in shared/src/utils/room.ts 🌪️
export function translatedRoomsInRange(
  rooms: Room[],
  roomRange: RoomRange | undefined,
) {
  if (roomRange === undefined) return msg`Toutes les chambres`

  const roomsInRange = roomsWithinRange(rooms, roomRange)

  if (roomsInRange.length === 0) return msg`Aucune chambre`

  if (roomsInRange.length === 1) return msg`Chambre ${roomsInRange[0]}`

  return msg`${roomsInRange.length} chambres, de ${roomsInRange[0]} à ${roomsInRange[roomsInRange.length - 1]}`
}
