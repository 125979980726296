import { Trans } from '@lingui/macro'
import React, { useRef, useState } from 'react'

interface Props {
  soundURL: string | undefined
}

export const Player: React.FC<Props> = ({ soundURL }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioPlayer = useRef<HTMLAudioElement>(null)

  function play() {
    if (soundURL && audioPlayer.current) {
      setIsPlaying(true)
      audioPlayer.current.play()
    }
  }

  function stop() {
    if (soundURL && audioPlayer.current) {
      audioPlayer.current.pause()
      audioPlayer.current.currentTime = 0
    }
  }

  function onPlaying() {
    if (audioPlayer.current && audioPlayer.current.paused) setIsPlaying(false)
  }

  return (
    <div
      className={`${
        soundURL
          ? 'cursor-pointer bg-blue-500 hover:bg-blue-400'
          : 'cursor-not-allowed bg-gray-200'
      } flex w-6 flex-col items-center justify-center rounded-full p-1`}
      onMouseUp={isPlaying ? stop : play}
    >
      <svg viewBox="0 0 60 60" fill="#ffffff">
        {isPlaying ? (
          <>
            <polygon points="12,7 25,7 25,52 12,52" />
            <polygon points="35,7 48,7 48,52 35,52" />
          </>
        ) : (
          <polygon points="15,5 55,30 15,55" />
        )}
      </svg>
      <audio src={soundURL} ref={audioPlayer} onTimeUpdate={onPlaying}>
        <Trans>Désolé, ce navigateur ne peut pas lire cet audio.</Trans>
      </audio>
    </div>
  )
}
