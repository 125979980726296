import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { DateTime } from 'luxon'
import { AlertType } from 'shared/types/alert'
import { ALERT_TYPE_TRANSLATE } from 'shared/types/alert.i18n'

export const getDatesAfter = (startDate: DateTime) => {
  const start = startDate
  const now = DateTime.now()
  const dates = []

  for (
    let current = start;
    current <= now;
    current = current.plus({ days: 1 })
  ) {
    dates.push(current.toISODate())
  }

  return dates
}

export const prepareChartDataForTotal = (totalData: Record<string, number>) => {
  const totalEntries = Object.entries(totalData || {}).map(([type, count]) => ({
    type: type,
    name: i18n._(ALERT_TYPE_TRANSLATE[type as AlertType]),
    value: count,
  }))

  const total = totalEntries.reduce((sum, current) => sum + current.value, 0)
  const threshold = 0.05 * total

  const others: { type: string; name: string; value: number }[] = []
  const mainData = totalEntries.filter((item) => {
    if (item.value < threshold) {
      others.push(item)
      return false
    }
    return true
  })

  if (others.length > 0) {
    const othersSum = others.reduce((sum, current) => sum + current.value, 0)
    mainData.push({
      type: 'Autres',
      name: t`Autres`,
      value: othersSum,
    })
  }

  return mainData
}
