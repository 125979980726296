import { Trans, t } from '@lingui/macro'
import {
  ChevronRight,
  Pencil,
  SquarePlus,
  User as UserIcon,
} from 'lucide-react'
import { useMemo, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { FacilityId, UserId } from 'shared/types/utils'
import { AddUserForm } from './AddUserForm'
import { EditUserForm } from './EditUserForm'
import { Alert } from './components/Alert'
import { useFirebase } from './hooks/useFirebase'

export const UserManager: React.FC = () => {
  const [alert, setAlert] = useState<Alert | null>(null)

  const [expandedFacilitiesIds, setExpandedFacilitiesIds] = useState(new Set())

  const {
    data: facilities,
    error: facilitiesError,
    loading: facilitiesLoading,
  } = useFirebase('facilities')

  const {
    data: users,
    error: usersError,
    loading: usersLoading,
  } = useFirebase('users')

  const [searchTerm, setSearchTerm] = useState('')

  const facilityIds = useMemo(() => Object.keys(facilities ?? {}), [facilities])

  // Trouver les utilisateurs sans facility valide
  const invalidUsers = useMemo(
    () =>
      Object.entries(users ?? {}).filter(([_, user]) => {
        const userFacilityIds = Object.keys(user.facilityIds ?? {})
        return (
          userFacilityIds.length === 0 ||
          userFacilityIds.some((id) => !facilityIds.includes(id))
        )
      }),
    [users, facilityIds],
  )

  const displayedFacilityIdsUserIds = useMemo(() => {
    if (users === null) return []

    const lowercasedTerm = searchTerm.toLocaleLowerCase()

    const displayedFacilityIdsUserIds: Record<FacilityId, UserId[]> = {}
    facilityIds.forEach((facilityId) => {
      const facilityMatch = facilityId
        .toLocaleLowerCase()
        .includes(lowercasedTerm)
      if (facilityMatch) displayedFacilityIdsUserIds[facilityId] = []

      Object.entries(users).forEach(([userId, user]) => {
        if (user.facilityIds?.[facilityId] === true)
          if (
            facilityMatch ||
            user.email.toLocaleLowerCase().includes(lowercasedTerm)
          ) {
            displayedFacilityIdsUserIds[facilityId] ??= []
            displayedFacilityIdsUserIds[facilityId].push(userId)
          }
      })
    })

    return displayedFacilityIdsUserIds
  }, [searchTerm, facilityIds, users])

  function toggleFacility(facilityId: FacilityId) {
    const newExpandedFacilities = new Set(expandedFacilitiesIds)
    if (newExpandedFacilities.has(facilityId)) {
      newExpandedFacilities.delete(facilityId)
    } else {
      newExpandedFacilities.add(facilityId)
    }
    setExpandedFacilitiesIds(newExpandedFacilities)
  }

  const [newUserFacilityId, setNewUserFacilityId] = useState<FacilityId>()

  const [editedUserId, setEditedUserId] = useState<UserId>()

  return (
    <div className="p-4">
      {facilitiesError || usersError ? (
        <Centered fullScreen>
          <Trans>Accès refusé</Trans>
        </Centered>
      ) : facilitiesLoading || usersLoading ? (
        <Centered fullScreen>
          <Trans>Chargement...</Trans>
        </Centered>
      ) : (
        <div>
          {alert && (
            <Alert
              message={alert.message}
              type={alert.type}
              onClose={() => setAlert(null)}
            />
          )}
          {newUserFacilityId && (
            <AddUserForm
              facilityId={newUserFacilityId}
              onSuccess={(message) => {
                setAlert({ message, type: 'success' })
                setNewUserFacilityId(undefined)
              }}
              onError={(message) => {
                setAlert({ message, type: 'error' })
                setNewUserFacilityId(undefined)
              }}
              onCancel={() => setNewUserFacilityId(undefined)}
            />
          )}
          {editedUserId && (
            <EditUserForm
              uid={editedUserId}
              user={users[editedUserId]}
              facilityIds={facilityIds}
              onError={(message) => {
                setAlert({ message, type: 'error' })
                setEditedUserId(undefined)
              }}
              onClose={() => setEditedUserId(undefined)}
            />
          )}
          <input
            type="text"
            placeholder={t`Rechercher par ID, nom, ou email d'utilisateur...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4 w-full max-w-md rounded border border-gray-300 p-2 focus:outline-none"
          />
          {/* Facilities */}
          {Object.entries(displayedFacilityIdsUserIds).map(
            ([facilityId, userIds]) => (
              <div key={facilityId} className="mb-2">
                <h2
                  className="flex cursor-pointer gap-2 text-xl text-gray-800"
                  onClick={() => toggleFacility(facilityId)}
                >
                  <ChevronRight
                    className={`inline transform transition-transform ${
                      expandedFacilitiesIds.has(facilityId) ? 'rotate-90' : ''
                    }`}
                  />
                  <span className="font-semibold">
                    <Highlight text={facilityId} search={searchTerm} />
                  </span>
                  <span>({userIds.length})</span>
                </h2>
                {expandedFacilitiesIds.has(facilityId) && (
                  <div className="ml-3 flex flex-col items-start gap-3 border-l-2 border-gray-300 py-2 pl-3">
                    <button
                      onClick={() => setNewUserFacilityId(facilityId)}
                      className="text-blue-800 hover:text-blue-600"
                    >
                      <SquarePlus className="inline" />
                      <span className="pl-2">
                        <Trans>Ajouter un utilisateur</Trans>
                      </span>
                    </button>
                    {userIds
                      .sort((userIdA, userIdB) =>
                        users[userIdA].email.localeCompare(
                          users[userIdB].email,
                        ),
                      )
                      .map((userId) => (
                        <div key={userId} className="flex items-center">
                          <UserIcon className="mr-2 inline text-gray-700" />
                          <span className="text-lg font-medium text-gray-700">
                            <Highlight
                              text={users[userId].email}
                              search={searchTerm}
                            />
                          </span>
                          <button
                            onClick={() => setEditedUserId(userId)}
                            className="ml-2 text-blue-800 hover:text-blue-600"
                          >
                            <Pencil className="inline" />
                          </button>
                          {Object.keys(users[userId].facilityIds ?? {}).length >
                            1 && (
                            <div className="group relative ml-2 inline-block">
                              <span className="rounded bg-black px-2 py-1 text-sm text-white">
                                {`${Object.keys(users[userId].facilityIds ?? {}).length} éts`}
                              </span>
                              <div className="absolute left-0 top-full hidden whitespace-nowrap rounded bg-black px-2 py-1 text-xs text-white group-hover:block">
                                {Object.keys(
                                  users[userId].facilityIds ?? {},
                                ).map((facilityId) => (
                                  <div key={facilityId}>{facilityId}</div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ),
          )}
          {/* Utilisateurs sans facility */}
          {invalidUsers.length > 0 && (
            <div className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800">
                <Trans>Utilisateurs invalides</Trans>
              </h2>
              <div className="ml-6 mt-2 border-l-2 border-gray-300 pl-4">
                {invalidUsers.map(([userId, user]) => (
                  <div key={userId} className="mt-4 flex items-center">
                    <UserIcon className="mr-2 inline text-gray-700" />
                    <span className="text-lg font-medium text-gray-700">
                      {user.email}
                    </span>
                    <button
                      onClick={() => setEditedUserId(userId)}
                      className="ml-2 text-blue-800 hover:text-blue-600"
                    >
                      <Pencil className="inline" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const Highlight: React.FC<{ text: string; search: string }> = ({
  text,
  search,
}) => {
  const pos = text.toLocaleLowerCase().indexOf(search.toLocaleLowerCase())
  if (pos === -1) return <> {text} </>
  else {
    return (
      <>
        {text.slice(0, pos)}
        <span className="bg-blue-200">
          {text.slice(pos, pos + search.length)}
        </span>
        {text.slice(pos + search.length)}
      </>
    )
  }
}
