import { Trans } from '@lingui/macro'
import React from 'react'

export const Footer: React.FC = () => {
  return (
    <div className="mt-8 border-t border-gray-300 pt-4 text-sm">
      <p>
        <Trans>
          💡 Pour accéder à ce rapport depuis votre téléphone, ouvrez un
          navigateur à l'adresse <code>oso-ai.com</code>
        </Trans>
      </p>
      <p>
        <Trans>
          Sélectionnez{' '}
          <span className="rounded bg-blue-600 px-2 text-white">
            Accès Clients
          </span>{' '}
          en haut et connectez-vous avec vos identifiants.
        </Trans>
      </p>
      <p>
        <Trans>
          Dans le menu{' '}
          <span className="rounded-full bg-gray-300 px-2">···</span> en haut de
          votre navigateur, sélectionnez <i>Ajouter à l'écran d'accueil</i>.
        </Trans>
      </p>
    </div>
  )
}
