import { Trans, t } from '@lingui/macro'
import { CreateNewUserRequestData } from 'common/httpCall'
import { getFunctions, httpsCallable } from 'firebase/functions'
import React, { useState } from 'react'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { FacilityId } from 'shared/types/utils'
import { app } from './firebase'

interface AddUserFormProps {
  facilityId: FacilityId
  onSuccess: (message: string) => void
  onError: (message: string) => void
  onCancel: () => void
}

export const AddUserForm: React.FC<AddUserFormProps> = ({
  facilityId,
  onSuccess,
  onError,
  onCancel,
}) => {
  const [email, setEmail] = useState('')
  const [sendEmail, setSendEmail] = useState(true)

  const functions = getFunctions(app, DEFAULT_REGION)
  const createNewUser = httpsCallable<CreateNewUserRequestData, void>(
    functions,
    'createNewUser',
  )

  async function createUser(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    await createNewUser({ email, facilityId, sendEmail })
      .then(() => {
        const message = sendEmail
          ? t`🎉 Email envoyé !`
          : t`🎉 Utilisateur ajouté !`
        onSuccess(message)
      })
      .catch((error) => onError(`Erreur : ${error.message}`))
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <form
        className="mx-4 w-full max-w-md rounded-lg bg-white p-6 shadow-lg"
        onSubmit={(event) => createUser(event)}
      >
        <div className="pb-2 font-bold">{facilityId}</div>
        <label
          htmlFor="user-email"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          <Trans>Email du nouvel utilisateur :</Trans>
        </label>
        <input
          type="email"
          className="w-full rounded border border-gray-300 p-2"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div className="mt-4 flex items-center">
          <input
            type="checkbox"
            id="send-email"
            className="mr-2"
            checked={sendEmail}
            onChange={() => setSendEmail(!sendEmail)}
          />
          <label htmlFor="send-email" className="text-sm text-gray-700">
            <Trans>Envoyer un email de bienvenue automatique</Trans>
          </label>
        </div>
        <div className="mt-4 flex justify-end space-x-4">
          <button
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            type="submit"
          >
            <Trans>Ajouter</Trans>
          </button>
          <button
            className="rounded bg-gray-500 px-4 py-2 font-bold text-white hover:bg-gray-700"
            onClick={onCancel}
          >
            <Trans>Annuler</Trans>
          </button>
        </div>
      </form>
    </div>
  )
}
