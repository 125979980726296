import { useLingui } from '@lingui/react'
import { DateLabelData } from 'common/types'
import { DateString } from 'shared/types/utils'
import { GraphConfig } from './utils/activity'
import { formatDate, getDay } from './utils/date'

interface Props<LabelType extends string> {
  dates: DateString[]
  dateValues: DateLabelData<LabelType>
  maxValue?: number
  displayScale?: boolean
  labels: readonly LabelType[]
  selectedLabel: string | undefined
  graphConfig: GraphConfig<LabelType>
}

export function DateGraph<LabelType extends string>({
  dates,
  dateValues,
  maxValue,
  displayScale,
  labels,
  selectedLabel,
  graphConfig,
}: Props<LabelType>) {
  const { _: lingui } = useLingui()

  maxValue ??= Math.max(
    ...Object.values(dateValues).flatMap(
      (labelValues) => Object.values(labelValues ?? {}) as number[],
    ),
  )

  return (
    <div className="flex flex-row overflow-x-auto">
      {displayScale && (
        <div className="text-xs sm:text-sm">
          <div className="flex h-24 flex-col items-end justify-between pr-1 md:pr-2">
            <div>{maxValue}</div>
            <div className="-mb-2">0</div>
          </div>
          <div>&nbsp;</div>
        </div>
      )}
      <div className="relative flex flex-1 flex-row justify-items-stretch gap-0.5 md:gap-1">
        <div className="absolute inset-x-0 top-0 h-24">
          {[...Array(5)].map((_, index) => (
            <div
              className="absolute inset-x-0 h-px bg-slate-300"
              key={index}
              style={{ bottom: `${(index + 1) * 20}%` }}
            />
          ))}
        </div>

        {dates.map((date) => (
          <div key={date} className="flex min-w-0 flex-1 flex-col gap-1">
            <div className="flex h-24 flex-row items-end justify-items-stretch">
              {labels.map((label) =>
                selectedLabel === undefined || selectedLabel === label ? (
                  <div
                    title={lingui(graphConfig[label].title)}
                    key={label}
                    style={{
                      height: `${
                        (95 *
                          (dateValues[date]?.[label] ?? 0) *
                          graphConfig[label].scale) /
                        (maxValue || 1)
                      }%`,
                    }}
                    className={`${graphConfig[label].color} max-h-full flex-1 opacity-80`}
                  />
                ) : null,
              )}
            </div>
            <div className="w-full text-center text-xs sm:text-sm">
              <span className="hidden lg:block">{formatDate(date)}</span>
              <span className="lg:hidden">{getDay(date)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
